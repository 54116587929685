<template>
  <div class="home">
    <Swiper></Swiper>
    <!-- <SubNav></SubNav>
    <box></box>
    <Sixbox></Sixbox>
    <four-boxa></four-boxa> -->
    <!-- 平台 -->
    <div class="platform">
      <img
        class="themeImg"
        :src="themeImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
      <img
        class="featureImg"
        :src="featureImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
      <img
        class="resultImg"
        :src="resultImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
      <!-- 最近活动 -->
      <img
        class="recentlyImg"
        :src="recentlyImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
      <p class="main">2021暑期专属【中学生的生涯研学】</p>
      <p class="sub">6月15日前预定交费享特惠价5480元  </p>
      <p class="sub">（开营前15日退团全额退款）</p>
      <div class="content">
        <img class="city" :src="item" v-for="(item,i) in cityList" :key="i" alt="">
      </div>
      <!-- 往期 -->
      <img
        class="previousImg"
        :src="previousImg"
        alt=""
        ondragstart="return false;"
        oncontextmenu="return false;"
      />
          <el-carousel :interval="4000" type="card" height="2.53rem">
        <el-carousel-item v-for="item in platfromDetail" :key="item">
          <!-- <h3 class="medium">{{ item }}</h3> -->
          <img class="platfromDetailImg" :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="message">
      <message></message>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import Box from "./modules/box.vue";
import Sixbox from "./modules/SixBoxa.vue";
import FourBoxa from "./modules/FourBoxa.vue";
import Message from "@/components/message/Message.vue";

export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    Message,
    Box,
    Sixbox,
    FourBoxa,
  },
  data() {
    return {
      themeImg: require("@/assets/1new/生涯研学/2.jpg"),
      featureImg: require("@/assets/1new/生涯研学/3.jpg"),
      resultImg: require("@/assets/1new/生涯研学/4.jpg"),
      recentlyImg: require("@/assets/1new/生涯研学/近期活动.jpg"),
      previousImg: require("@/assets/1new/生涯研学/往期回顾.jpg"),
      cityList:[
        require("@/assets/1new/生涯研学/5.jpg"),require("@/assets/1new/生涯研学/6.jpg"),require("@/assets/1new/生涯研学/7.jpg"),
      ],
        platfromDetail: [
        require("@/assets/1new/生涯云平台/轮播图片/1.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/2.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/3.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/4.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/5.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/6.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/7.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/8.jpg"),
        require("@/assets/1new/生涯云平台/轮播图片/9.jpg"),
      ],
    };
  },
});
</script>
<style lang="scss" scoped>
// .news {
//   width: 1200px;
//   // min-height: 200px;
//   background-color:skyblue;
//   height: 100%;
//   margin-bottom: 20px;
//   margin-top: 20px;
//   position: relative;
//   display: block;
// }
.platform {
  width: 1200px;
  min-height: 300px;
  background-color: #fff;
  .themeImg {
    width: 1200px;
    height: 692px;
  }
  .featureImg {
    width: 1200px;
    height: 856px;
  }
  .resultImg {
    width: 1200px;
    height: 363px;
  }
  .recentlyImg {
    width: 1200px;
    height: 71px;
  }
  .main{
    text-align: center;
    font-size: 44px;
    line-height: 64px;
    color: #7f3008;
  }
  .sub{
    text-align: center;
    line-height: 53px;
    font-size: 36px;
    color: #454543;
  }
  .content{
    margin-top: 40px;
    width: 1200px;
    height: 100%;
    margin-bottom: 40px;
    .city{
      width: 400px;
      height: 833px;
    }
  }
  .previousImg {
    width: 1200px;
    height: 81px;
  }
}
.message {
  width: 1200px;
  // background-color: skyblue;
  display: block;
  height: 100%;
  margin-bottom: 20px;
  margin-top: 56px;
}
</style>
